import React from "react";
import AddRentComp from "../common/components/addrent/AddRentComp";

const AddRent = () => {
  <>
    <section
      className="overviewMainSection"
      style={{ border: "1px solid red" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <AddRentComp />
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default AddRent;
