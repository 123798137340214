import React, { useEffect, useState } from "react";
import SideBar from "../component/SideBar";
import { Controller, set, useForm } from "react-hook-form";
import InputField from "../common/components/inputfield/InputField";
import addIcon from "../common/assets/images/add_circle.svg";
import wrong from "../common/assets/images/wrong.svg";
import axios from "axios";
import { useMutation } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router";
import AlertModal from "../common/components/popupmodel/AlertModal";

export default function NewTechnician() {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const [file, setFile] = useState(null);
  const [base64Image, setBase64Image] = useState({});
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  // console.log("state", state);
  // console.log("base64Image", base64Image);

  // const { aadharFile, panFile } = watch();
  // console.log("panFile", panFile);
  // console.log("aadharFile", aadharFile);
  // console.log("state", state);

  useEffect(() => {
    if (state) {
      setBase64Image({
        aadharFile: state?.adharImage,
        panFile: state?.panImage,
        photoFile: state?.photo,
        documentUpload: state?.documentUpload,
      });
      setValue("firstName", state?.firstName);
      setValue("lastName", state?.lastName);
      setValue("address", state?.address);
      setValue("mobileNo", state?.mobileNo);
      setValue("alternativeNumber", state?.alternativeNumber);
      setValue("email", state?.emailId);
      setValue("role", state?.role);
      setValue("department", state?.department);
      setValue("aadhar", state?.adharNo);
      setValue("panNo", state?.panNo);
      setValue("jobType", state?.jobType);
      setValue("place", state?.place);
      setValue("pinCode", state?.pinCode);
    }
  }, []);

  const handleImageInputChange = (event, fileName) => {
    // console.log("event", event.target.files[0], fileName);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(",") + 1
        );
        setBase64Image({ ...base64Image, [fileName]: base64LetterRemove });
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };

  const removeKey = (fileName) => {
    // console.log("value", fileName);
    setBase64Image({ ...base64Image, [fileName]: "" });
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/technicians`,
          // "https://api.2kvirtualworld.com/CRM/technicians",
          postData
        );
        setOpen("TECHNICION ADDED");

        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/technicians/${state?.employeeID}`,
        postData
      ),
    {
      onSuccess: (data) => {
        setUpdateOpen("TECHNICION UPDATED");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );
  const Submit = (data) => {
    console.log("data", data);
    const formValue = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      address: data?.address,
      mobileNo: data?.mobileNo,
      alternativeNumber: data?.alternativeNumber,
      emailId: data?.email,
      role: data?.role,
      department: data?.department,
      adharNo: data?.aadhar,
      panNo: data?.panNo,
      panImage: base64Image?.panFile,
      adharImage: base64Image?.aadharFile,
      photo: base64Image?.photoFile,
      documentUpload: base64Image?.documentUpload,
      jobType: data?.jobType,
      place: data?.place,
      pinCode: data?.pinCode,
    };
    // console.log("formValue", formValue);
    if (state && state?.employeeID) {
      console.log("update", state?.id);

      UpdateFormValue.mutate(formValue);
    } else {
      mutate(formValue);
    }
    // handleImageInputChange(data?.aadharFile);
  };

  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="row bg-white px-3 pb-4"
        style={{
          height: "95vh",
          borderRadius: "10px",
          overflowY: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <header
          className="fs-2 fw-bold py-2 mb-3 ps-4 text-center"
          style={{
            height: "10%",
            position: "sticky",
            top: "0px",
            backgroundColor: "#fff",
          }}
        >
          {state && state ? <>EDIT TECHNICIAN</> : <>NEW TECHNICIAN</>}
        </header>
        <section className="py-1">
          <form onSubmit={handleSubmit(Submit)}>
            <div className=" col-12 mb-3  d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">First Name</label>
                <Controller
                  control={control}
                  name="firstName"
                  render={(field) => (
                    <InputField
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Last Name</label>
                <Controller
                  control={control}
                  name="lastName"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            {/* <div className="col-12 my-2 d-flex">
                <div className="col-6 ">
                  <label className=" col-5  addUser_ticket_label">
                    Mobile No
                  </label>

                  <Controller
                    control={control}
                    name="mobilenumber"
                    render={(field) => (
                      <InputField
                        addUserInputCss="--addUser_inputFields"
                        {...field}
                        type="number"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div className="col-6 ">
                  <label className=" col-5  addUser_ticket_label">
                    Alternate No
                  </label>

                  <Controller
                    control={control}
                    name="alternatenumber"
                    render={(field) => (
                      <InputField
                        addUserInputCss="--addUser_inputFields"
                        {...field}
                        type="number"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div> */}

            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Address
                </label>
                <Controller
                  control={control}
                  name="address"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" col-9 --addUser_companyName_inputField"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className=" col-12 mb-3 d-flex">
              {/* <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Job</label>
                <Controller
                  control={control}
                  // name="role"
                  name="jobType"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
               
                />
              </div> */}
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Place</label>
                <Controller
                  control={control}
                  name="place"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Pincode</label>
                <Controller
                  control={control}
                  name="pinCode"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="number"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Mobile No</label>
                <Controller
                  control={control}
                  name="mobileNo"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">
                  Alternate No
                </label>
                <Controller
                  control={control}
                  name="alternativeNumber"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Email ID
                </label>
                <Controller
                  control={control}
                  name="email"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" col-9 --addUser_companyName_inputField"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Role</label>
                <Controller
                  control={control}
                  // name="role"
                  name="role"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                  // <select
                  //   value={value}
                  //   onChange={onChange}
                  //   className="--addUser_inputFields"
                  //   style={{ "text-transform": "capitalize" }}
                  // >
                  //   <option> </option>
                  //   <option value="admin">Admin</option>
                  //   <option value="limitedAdmin">Limited Admin</option>
                  //   <option value="superAdmin">Super Admin</option>
                  // </select>

                  // )}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Department</label>
                <Controller
                  control={control}
                  name="department"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Job Type</label>
                <Controller
                  name="jobType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <select {...field} className="--addUser_inputFields">
                      <option value="">Job Type</option>
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                    </select>
                  )}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6">
                <label className="col-5 addUser_ticket_label">AADHAR</label>
                <Controller
                  control={control}
                  name="aadhar"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                />
              </div>
              <div className="col-6">
                <label
                  htmlFor="input-file"
                  className="col-6 aadharFileInput text-center  justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
                <img
                  style={{ height: "30px", marginLeft: "10px" }}
                  alt=""
                  src={`data:image/jpeg;base64,${base64Image?.aadharFile}`}
                />
                {base64Image?.aadharFile && (
                  <img
                    onClick={() => {
                      removeKey("aadharFile");
                    }}
                    className=" ps-2 h-75"
                    src={wrong}
                    alt=""
                  />
                )}

                <Controller
                  control={control}
                  name="aadharFile"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className="fileInput"
                      value={value}
                      // onChange={onChange}
                      onChange={(e) =>
                        onChange(handleImageInputChange(e, "aadharFile"))
                      }
                      type="file"
                      name="uploadfile"
                      id="input-file"
                      style={{ display: "none" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6">
                <label className="col-5 addUser_ticket_label">PAN</label>
                <Controller
                  control={control}
                  name="panNo"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="text-uppercase panCard_inputFields"
                      type="text"
                    />
                  )}
                />
              </div>
              <div className="col-6">
                {/* <label className="col-5 addUser_ticket_label">AADHAR</label> */}
                <label
                  for="panImg"
                  className="col-6 aadharFileInput text-center  justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
                <img
                  style={{ height: "30px", marginLeft: "10px" }}
                  alt=""
                  src={`data:image/jpeg;base64,${base64Image?.panFile}`}
                />
                {base64Image?.panFile && (
                  <img
                    onClick={() => {
                      removeKey("panFile");
                    }}
                    className=" ps-2 h-50"
                    src={wrong}
                    alt=""
                  />
                )}
                <Controller
                  control={control}
                  name="panFile"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className="fileInput"
                      // onChange={onChange}
                      onChange={(e) =>
                        onChange(handleImageInputChange(e, "panFile"))
                      }
                      type="file"
                      name="uploadfile"
                      id="panImg"
                      style={{ display: "none" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Photo
                </label>

                <label
                  for="photoImg"
                  className="col-8 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
                <img
                  style={{ height: "30px", marginLeft: "10px" }}
                  alt=""
                  src={`data:image/jpeg;base64,${base64Image?.photoFile}`}
                />
                {base64Image?.photoFile && (
                  <img
                    onClick={() => {
                      removeKey("photoFile");
                    }}
                    className=" ps-2 h-75"
                    src={wrong}
                    alt=""
                  />
                )}

                <Controller
                  control={control}
                  name="photoFile"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className=" ms-2 "
                      type="file"
                      name="uploadfile"
                      id="photoImg"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        onChange(handleImageInputChange(e, "photoFile"))
                      }
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Document Upload
                </label>

                <label
                  for="documentUpload"
                  className="col-8 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
                <img
                  style={{ height: "30px", marginLeft: "10px" }}
                  alt=""
                  src={`data:image/jpeg;base64,${base64Image?.documentUpload}`}
                />
                {base64Image?.documentUpload && (
                  <img
                    onClick={() => {
                      removeKey("photoFile");
                    }}
                    className=" ps-2 h-75"
                    src={wrong}
                    alt=""
                  />
                )}

                <Controller
                  control={control}
                  name="documentUpload"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className=" ms-2 "
                      type="file"
                      name="documentUpload"
                      id="documentUpload"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        onChange(handleImageInputChange(e, "documentUpload"))
                      }
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className="col-12 addUser_submitBtn_field">
              <button className=" col-5 addUser_submitBtn" type="submit">
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "SUBMIT"
                )}
              </button>
            </div>
          </form>
        </section>
        <AlertModal
          open={open}
          setOpen={setOpen}
          setUpdateOpen={setUpdateOpen}
          UpdateOpen={UpdateOpen}
          navigate={"/viewTechnicians"}
          setErrorOpen={setErrorOpen}
          errorOpen={errorOpen}
        />
      </div>
    </div>
  );
}
// {
// "firstName": "shalini",
// "lastName": "subramani",
// "address": "123 Main St, City, Country",
// "mobileNo": "1234567890",
// "alternateNo": "0987654321",
// "modelName": "Model X",
// "product": "Smartphone",
// "issue": "Screen not working",
// "photo": "iVBORw0KGgoAAAANSUhEUgAAAAUA",
// "selectSlot": "10:00 AM - 12:00 PM",
// "totalAmount": 500.00,
// "actualAmount": 450.00,
// "netAmount": 400.00,
// "technicianShare": 300.00,
// "ownerShare": 100.00
// }
