import React from "react";

const AddRentComp = () => {
  return (
    <>
      <div className="overview-main-block">
        <h6>ADD RENT</h6>
      </div>
    </>
  );
};

export default AddRentComp;
