import React from "react";
import OverView from "../../common/components/overviewcomp/OverView";

const OverviewScreen = () => {
  return (
    <>
      <section className="overviewMainSection" >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12" >
              <OverView />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OverviewScreen;
