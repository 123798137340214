import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../common/components/inputfield/InputField";
import addIcon from "../common/assets/images/add_circle.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import wrong from "../common/assets/images/wrong.svg";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router";
import AlertModal from "../common/components/popupmodel/AlertModal";

export default function AddRequest() {
  // Get the current time in HH:mm format
  // const getCurrentTime = () => new Date().toTimeString().slice(0, 5); // HH:mm format (e.g., 14:30)

  // Function to get the current system time in HH:mm format
  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().slice(0, 5); // Format time as HH:mm (e.g., "14:30")
  };

  // State to hold the current time from system
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const { control, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: {
      appointmentTime: currentTime,
      selectSlot: currentTime,
      amcTime: currentTime,
    },
  });

  // Update time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = getCurrentTime();
      setCurrentTime(newTime);
      setValue("appointmentTime", newTime);
      setValue("selectSlot", newTime);
    }, 60000); // Update every minute (60000ms)

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [setValue]);

  const [file, setFile] = useState(null);
  const [base64Image, setBase64Image] = useState({});
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [deleteSuccessfully, setDeleteSuccessfully] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const isChecked = watch("amc", false); // Default is false (unchecked)
  // const handleImageInputChange = (event) => {
  //   console.log("event", event.target.files[0]);

  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const base64String = reader.result;
  //       const base64LetterRemove = base64String.substring(
  //         base64String.indexOf(",") + 1
  //       );
  //       setBase64Image(base64LetterRemove);
  //     };
  //     reader.onerror = (error) => {
  //       console.error(
  //         "Error occurred while converting image to base64:",
  //         error
  //       );
  //     };
  //   }
  // };
  const handleImageInputChange = (event, fileName) => {
    console.log("event", event.target.files[0]);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(",") + 1
        );
        // setBase64Image(base64LetterRemove);
        setBase64Image({ ...base64Image, [fileName]: base64LetterRemove });
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };
  const removeKey = (fileName) => {
    console.log("value", fileName);
    setBase64Image({ ...base64Image, [fileName]: "" });
  };
  console.log("state", state);

  useEffect(() => {
    if (state) {
      // setBase64Image(state?.photo);
      setBase64Image({
        productImg: state?.productImage,
        modelImg: state?.modelImage,
        serialImg: state?.serialImage,
      });
      setValue("firstName", state?.firstName);
      setValue("lastName", state?.lastName);
      setValue("address", state?.address);
      setValue("mobileNo", state?.mobileNo);
      setValue("alternateNo", state?.alternateNo);
      setValue("product", state?.product);
      setValue("modalName", state?.modelName);
      setValue("issue", state?.issue);
      setValue("serviceName", state?.serviceName);
      setValue("selectSlot", state?.selectSlot);
      setValue("totalAmt", state?.totalAmount);
      setValue("actualAmt", state?.actualAmount);
      setValue("netAmt", state?.netAmount);
      setValue("technicianShare", state?.technicianShare);
      setValue("ownerShare", state?.ownerShare);
      setValue("place", state?.place);
      setValue("pincode", state?.pincode);
      setValue("referenceName", state?.referenceName);
      setValue("brandName", state?.brandName);
      setValue("serialNo", state?.serialNo);
      setValue("replaceMaterialName", state?.replaceMaterialName);
      setValue("replaceMaterialCharge", state?.replaceMaterialCharge);
      setValue("referencePhoneNumber", state?.referencePhoneNumber);
      setValue("appointmentDate", state?.appointmentDate);
      setValue("appointmentTime", state?.appointmentTime);
      setValue("purchaseMaterialCost", state?.purchaseMaterialCost);
      setValue("transportCharges", state?.transportCharges);
      setValue("otherCharges", state?.otherCharges);
      setValue("transportKM", state?.transportKM);
      setValue("transportAmount", state?.transportAmount);
      setValue("serviceCharge", state?.serviceCharge);
      setValue("otherDetails", state?.otherDetails);
      setValue("totalTechnicianShare", state?.totalTechnicianShare);
      setValue("amcCharge", state?.amcCharge);
      setValue("amcDate", state?.amcDate);
      setValue("amcTime", state?.amcTime);
      setValue("amcYear", state?.amcYear);
      setValue("amc", state?.amc);
      setValue("warrantyDetails", state?.warrantyDetails);

      // setValue("ownerShare", state?.ownerShare);
    }
  }, [state, setValue]);

  const {
    replaceMaterialCharge,
    serviceCharge,
    purchaseMaterialCost,
    transportKM,
    transportAmount,
    transportCharges,
    technicianShare,
    otherCharges,
  } = watch();
  let total = Number(replaceMaterialCharge) + Number(serviceCharge);
  let materialshare =
    Number(replaceMaterialCharge) - Number(purchaseMaterialCost);
  let totalshare = Number(Number(serviceCharge) + Number(materialshare)) / 2;

  let trasportamt = Number(transportKM) * Number(transportAmount);
  let totaltechshare =
    Number(technicianShare) + Number(transportCharges) + Number(otherCharges);
  useEffect(() => {
    setValue("totalAmt", Number(total && total) || null);
    setValue("transportCharges", Number(trasportamt && trasportamt) || null);
    setValue("technicianShare", Number(totalshare && totalshare) || null);
    setValue("ownerShare", Number(totalshare && totalshare) || null);
    setValue(
      "totalTechnicianShare",
      Number(totaltechshare && totaltechshare) || null
    );
  }, [
    total,
    setValue,
    trasportamt,
    transportCharges,
    totalshare,
    totaltechshare,
  ]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);

        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/requests`,
          postData
        );
        // setConfirmAlert(true);
        setDeleteConfirmAlert(false);
        navigate("/");
        // deleteConfirmAlert(true);
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/requests/edit/${state?.requestID}`,
        postData
      ),
    {
      onSuccess: (data) => {
        setDeleteConfirmAlert(false);
        navigate("/");
        // deleteConfirmAlert(true);
        // Navigate("/viewUserManagement");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );

  const Submit = (data) => {
    const formValue = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      address: data?.address,
      mobileNo: data?.mobileNo,
      alternateNo: data?.alternateNo,
      modelName: data?.modalName,
      product: data?.product,
      issue: data?.issue,
      photo: "",
      serviceName: data?.serviceName,
      selectSlot: data?.selectSlot,
      totalAmount: data?.totalAmt,
      actualAmount: data?.actualAmt,
      netAmount: data?.netAmt,
      technicianShare: data?.technicianShare,
      ownerShare: data?.ownerShare,
      place: data?.place,
      pincode: data?.pincode,
      referenceName: data?.referenceName,
      referencePhoneNumber: data?.referencePhoneNumber,
      appointmentDate: moment(data?.appointmentDate).format("YYYY-MM-DD"),
      appointmentTime: data?.appointmentTime,
      transportCharges: data?.transportCharges,
      otherCharges: data?.otherCharges,
      purchaseMaterialCost: data?.purchaseMaterialCost,
      transportKM: data?.transportKM,
      transportAmount: data?.transportAmount,
      brandName: data?.brandName,
      serialNo: data?.serialNo,
      replaceMaterialName: data?.replaceMaterialName,
      replaceMaterialCharge: data?.replaceMaterialCharge,
      serviceCharge: data?.serviceCharge,
      productImage: base64Image?.productImg,
      modelImage: base64Image?.modelImg,
      serialImage: base64Image?.serialImg,
      otherDetails: data?.otherDetails,
      totalTechnicianShare: data?.totalTechnicianShare,
      amcCharge: data && data?.amcCharge,
      amcDate: moment(data && data?.amcDate).format("YYYY-MM-DD") || null,
      amcTime: (data && data?.amcTime) || null,
      amcYear: (data && data?.amcYear) || null,
      amc: (data && data?.amc) || null,
      warrantyDetails: (data && data?.warrantyDetails) || null,
    };
    setDeleteConfirmAlert(formValue);
    // console.log("confirmAlert", confirmAlert);
    // if (confirmAlert) {
    // setDeleteConfirmAlert()
    // console.log("state", confirmAlert);

    // handleImageInputChange(data?.aadharFile);
  };
  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="row bg-white px-3 pb-4 "
        style={{
          height: "96vh",
          borderRadius: "10px",
          overflowY: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <header
          className="fs-2 fw-bold py-1 ps-4  text-center"
          style={{
            height: "10%",
            position: "sticky",
            top: "0px",
            backgroundColor: "#fff",
          }}
        >
          {state && state ? <>EDIT REQUEST </> : <>ADD REQUEST</>}
        </header>
        <section>
          <form onSubmit={handleSubmit(Submit)}>
            <div style={{}}>
              <div className="addUser_ticket_label py-3">
                <h4 className="fw-bold addrequest-heading">
                  {" "}
                  Customer Details
                </h4>
                <div className=" col-12 mb-3 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      First Name
                    </label>
                    <Controller
                      control={control}
                      name="firstName"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Last Name
                    </label>
                    <Controller
                      control={control}
                      name="lastName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 ">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Address
                    </label>
                    <Controller
                      control={control}
                      name="address"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" col-9 --addUser_companyName_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">Place</label>
                    <Controller
                      control={control}
                      name="place"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Pincode
                    </label>
                    <Controller
                      control={control}
                      name="pincode"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Mobile No
                    </label>
                    <Controller
                      control={control}
                      name="mobileNo"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Alternate No
                    </label>
                    <Controller
                      control={control}
                      name="alternateNo"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Reference Person
                    </label>
                    <Controller
                      control={control}
                      name="referenceName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Reference Number
                    </label>
                    <Controller
                      control={control}
                      name="referencePhoneNumber"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="tele"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6 m-0 p-0">
                    <label className="col-5 addUser_ticket_label">
                      Appointment Date
                    </label>

                    <Controller
                      name="appointmentDate"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          className="--addUser_inputFields_date"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)} // Pass the date to react-hook-form
                          dateFormat="yyyy/MM/dd"
                          // placeholderText="yyyy/MM/dd"
                          minDate={new Date()} // Set minimum date to today
                        />
                      )}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">Time</label>
                    {/* <Controller
                      control={control}
                      name="appointmentTime"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="time"
                        />
                      )}
                    /> */}
                    <Controller
                      control={control}
                      name="appointmentTime"
                      defaultValue={currentTime} // Use current time as default
                      render={({ field }) => (
                        <input
                          {...field} // Spread the field props to InputField
                          type="time" // Set type to "time"
                          className="--addUser_inputFields" // Optional CSS class for custom styling
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              {/* Product Details */}
              <div className="py-3">
                <h4 className="fw-bold addrequest-heading"> Product Details</h4>

                <div className=" col-12 mb-3 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Product
                    </label>
                    <Controller
                      control={control}
                      name="product"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Model Name
                    </label>
                    <Controller
                      control={control}
                      name="modalName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-3 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Brand Name
                    </label>
                    <Controller
                      control={control}
                      name="brandName"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Serial No
                    </label>
                    <Controller
                      control={control}
                      name="serialNo"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 ">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Issue
                    </label>
                    <Controller
                      control={control}
                      name="issue"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="col-9 --addUser_companyName_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                {/* <div className="col-12 mb-2">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Product Image
                    </label>

                    <label
                      for="photoImg"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="h-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image}`}
                    />
                    {base64Image && (
                      <img
                        onClick={() => {
                          setBase64Image("");
                          // removeKey("photoFile");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="photoFile"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          // value={value}
                          className=" ms-2 "
                          type="file"
                          // name="uploadfile"
                          id="photoImg"
                          style={{ display: "none" }}
                          onChange={(e) => onChange(handleImageInputChange(e))}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div> */}
                <div className="col-12 mb-3">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Product Image
                    </label>

                    <label
                      for="photoImg"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center"
                      style={{ width: "50%" }}
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="h-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image?.productImg}`}
                    />
                    {base64Image && (
                      <img
                        onClick={() => {
                          // setBase64Image("");
                          removeKey("productImg");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="productImage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          value={value}
                          className=" ms-2 "
                          type="file"
                          name="uploadfile"
                          id="photoImg"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            onChange(handleImageInputChange(e, "productImg"))
                          }
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Model Image
                    </label>

                    <label
                      for="modelImage"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center"
                      style={{ width: "50%" }}
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="hZ-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image?.modelImg}`}
                    />
                    {base64Image && (
                      <img
                        onClick={() => {
                          // setBase64Image("");
                          removeKey("modelImg");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="modelImage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          value={value}
                          className=" ms-2 "
                          type="file"
                          name="uploadfile"
                          id="modelImage"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            onChange(handleImageInputChange(e, "modelImg"))
                          }
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Serial Image
                    </label>

                    <label
                      for="serialImg"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center"
                      style={{ width: "50%" }}
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="h-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image?.serialImg}`}
                    />
                    {base64Image && (
                      <img
                        onClick={() => {
                          // setBase64Image("");
                          removeKey("serialImg");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="serialImage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          value={value}
                          className=" ms-2 "
                          type="file"
                          name="uploadfile"
                          id="serialImg"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            onChange(handleImageInputChange(e, "serialImg"))
                          }
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 px-1 my-1">
                  <div>
                    <label className="d-flex align-items-center gap-2">
                      <Controller
                        name="amc"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            {...field}
                            style={{ width: "18px", height: "18px" }}
                          />
                        )}
                      />
                      Annual Maintanence Charge
                    </label>
                  </div>
                </div>
                {isChecked && (
                  <>
                    <div className="col-12 my-3 d-flex">
                      <div className="col-6 ">
                        <label className="col-5 addUser_ticket_label">
                          AMC Date
                        </label>
                        <Controller
                          name="amcDate"
                          control={control}
                          defaultValue={new Date()} // Set default value to today's date
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              className="--addUser_inputFields_date"
                              selected={field.value}
                              onChange={(date) => field.onChange(date)} // Pass the date to react-hook-form
                              dateFormat="yyyy/MM/dd"
                              minDate={new Date()} // Set minimum date to today
                            />
                          )}
                        />
                      </div>
                      <div className="col-6 ">
                        <label className="col-5 addUser_ticket_label">
                          AMC Time
                        </label>
                        <Controller
                          control={control}
                          name="amcTime"
                          defaultValue={currentTime} // Use current time as default
                          render={({ field }) => (
                            <input
                              {...field} // Spread the field props to InputField
                              type="time" // Set type to "time"
                              className="--addUser_inputFields" // Optional CSS class for custom styling
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 my-2">
                      <div className="d-flex">
                        <label
                          style={{ width: "20%" }}
                          className="addUser_ticket_label"
                        >
                          AMC Year
                        </label>
                        <Controller
                          name="amcYear"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <select
                              {...field}
                              className="col-9 --addUser_companyName_inputField"
                            >
                              <option value=""></option>
                              <option value="1 ">1 </option>
                              <option value="2 ">2 </option>
                              <option value="3 ">3 </option>
                              <option value="4 ">4 </option>
                              <option value="5 ">5 </option>
                            </select>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 my-3 d-flex">
                      <div className="col-6 ">
                        <label className="col-5 addUser_ticket_label">
                          AMC Charge
                        </label>
                        <Controller
                          name="warrantyDetails"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <select
                              {...field}
                              className="--addUser_inputFields"
                            >
                              <option value=""></option>
                              <option value="With Material">
                                With Material
                              </option>
                              <option value="Without Material">
                                Without Material
                              </option>
                            </select>
                          )}
                        />
                      </div>
                      <div className="col-6">
                        <Controller
                          control={control}
                          name="amcCharge"
                          render={(field) => (
                            <InputField
                              {...field}
                              addUserInputCss=" --addUser_inputFields"
                              type="number"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-12 py-3">
                <h4 className="fw-bold addrequest-heading">
                  {" "}
                  Payment and Time Details
                </h4>
                {/* Product Details */}
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Product Name
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="serviceName"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Select Time
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="selectSlot"
                      defaultValue={currentTime} // Use current time as default
                      render={({ field }) => (
                        <input
                          {...field} // Spread the field props to InputField
                          type="time" // Set type to "time"
                          className="--addUser_inputFields" // Optional CSS class for custom styling
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "25%" }}
                    className=" addUser_ticket_label"
                  >
                    Replace Material Name
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="replaceMaterialName"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "25%" }}
                    className=" addUser_ticket_label"
                  >
                    Purchase Material Cost
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="purchaseMaterialCost"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "25%" }}
                    className=" addUser_ticket_label"
                  >
                    Replace Material Charge
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="replaceMaterialCharge"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Service Charge
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="serviceCharge"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Total Amount
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="totalAmt"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Technician Share
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="technicianShare"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Owner Share
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="ownerShare"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between align-items">
                  <label
                    style={{ width: "35%" }}
                    className=" addUser_ticket_label"
                  >
                    Transport Charge
                  </label>
                  <Controller
                    control={control}
                    name="transportKM"
                    render={(field) => (
                      <InputField
                        transportInput="transportInput"
                        {...field}
                        type="number"
                        placeholder="K/M"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="transportAmount"
                    render={(field) => (
                      // <input
                      //   {...field}
                      //   type="number"
                      //   placeholder="Amt Per K/M"
                      //   className="transportInput"
                      // />
                      <InputField
                        {...field}
                        type="number"
                        transportInput="transportInput"
                        placeholder="Amt Per K/M"
                      />
                    )}
                  />

                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="transportCharges"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between align-items-center">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Other Charge
                  </label>
                  <Controller
                    control={control}
                    name="otherDetails"
                    render={(field) => (
                      <InputField
                        transportInput="transportInput"
                        {...field}
                        type="text"
                        placeholder="Others Details"
                      />
                    )}
                  />
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="otherCharges"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-between">
                  <label
                    style={{ width: "30%" }}
                    className=" addUser_ticket_label"
                  >
                    Total Technician Share
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="totalTechnicianShare"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 addUser_submitBtn_field ">
                <button
                  // onClick={() => {
                  //   setDeleteConfirmAlert("add");
                  // }}
                  className=" col-5 addUser_submitBtn"
                  type="submit"
                >
                  {/* {" "}
                  SUBMIT */}
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </div>
          </form>
        </section>
        <AlertModal
          deleteConfirmAlert={deleteConfirmAlert}
          setDeleteConfirmAlert={setDeleteConfirmAlert}
          setDeleteSuccessfully={setDeleteSuccessfully}
          deleteSuccessfully={deleteSuccessfully}
          isLoading={isLoading}
          yesOnClick={() => {
            // HandlerApi(deleteConfirmAlert);
            // mutate(deleteConfirmAlert)
            if (state && state?.requestID) {
              console.log("state?.requestID", deleteConfirmAlert);
              UpdateFormValue.mutate(deleteConfirmAlert);
            } else {
              mutate(deleteConfirmAlert);
            }
          }}
        />
      </div>
    </div>
  );
}
